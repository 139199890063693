import React, { useEffect, useState } from "react";
import { Success } from "./Success";
import { ConfirmPin } from "./ConfirmPin";
import { SetPinCode } from "./SetPinCode";
import { useLocation } from "react-router-dom";
import { getSearchParams, removeStartingQuestionMark } from "../utils/urls";

export enum Step {
  ADD = "ADD",
  CONFIRM = "CONFIRM",
  SUCCESS = "SUCCESS",
}

export default function AddPinCode() {
  const location = useLocation();
  const [accessToken, setAccessToken] = useState("");
  const [step, setStep] = useState<Step>(Step.ADD);
  const [pincode, setPincode] = useState<string[]>([]);
  const [platform, setPlatform] = useState<string>("");
  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const [canProceedAfterConfirm, setCanProceedAfterConfirm] = useState<boolean>(false);

  useEffect(() => {
    // Parse query parameters from location.search
    // Access specific query parameters
    const {
      accessToken,
      platform,
      redirectUrl,
      proceedAfterConfirm
    } = getSearchParams(location.search);

    setAccessToken(accessToken);
    setPlatform(platform ?? "");
    setRedirectUrl(redirectUrl ?? "");

    // !NOTE: This will only be True or NOT Undefined, if it was sent from PinCode.tsx,
    // allowing user to proceed to get Tokenized Share.
    // Otherwise, user simply needs to set up PIN code and go back to the parent window.
    setCanProceedAfterConfirm(proceedAfterConfirm === "true" ? true : false);
  }, [location]);

  if (step === Step.SUCCESS) return (
    <Success
      platform={platform}
      url={redirectUrl}
      proceedAfterConfirm={canProceedAfterConfirm}
      // this will only be needed if canProceedAfterConfirm is true
      getShareUrl={canProceedAfterConfirm ? removeStartingQuestionMark(location.search) : ""}
    />
  );
  else if (step === Step.CONFIRM) {
    return (
      <ConfirmPin
        setStep={setStep}
        pincode={pincode.join("")}
        accessToken={accessToken}
      />
    );
  } else {
    return (
      <SetPinCode setStep={setStep} pincode={pincode} setCode={setPincode} />
    );
  }
}
