import axios, { AxiosError } from "axios";
import { removeTrailingSlash } from "./utils/urls";
import {
  AuthParams,
  GetShareParams,
  SetPinCodeResponse,
  SetPinParams,
  UserInfo,
  UserInfoResponse,
  createOrRecoverAccountResponse,
} from "./types";

const api = axios.create({
  baseURL: removeTrailingSlash(process.env.REACT_APP_BASE_URL as string),
});

const sphereOneEndpoints = {
  user: "/user",
  pincode: "/user/pincode",
  createOrRecoverAccount: "/createOrRecoverAccount",
};

export const SphereOneApi = {
  getUser: async (params: AuthParams): Promise<UserInfo> => {
    const res = await api.get(sphereOneEndpoints.user, {
      headers: { Authorization: `Bearer ${params.accessToken}` },
    });
    const userInfo = res.data as UserInfoResponse;
    if (!userInfo.data)
      throw new Error(userInfo.error ?? "Unable to fetch UserInfo.");
    return userInfo.data;
  },
  setPin: async (params: SetPinParams): Promise<string> => {
    const res = await api.post(
      sphereOneEndpoints.pincode,
      {
        pinCode: params.pincode,
      },
      { headers: { Authorization: `Bearer ${params.accessToken}` } }
    );
    const setPinResponse = res.data as SetPinCodeResponse;
    if (!setPinResponse.data)
      throw new Error(setPinResponse.error ?? "Unable to set PIN for user.");
    return setPinResponse.data;
  },
  getShare: async (params: GetShareParams): Promise<string> => {
    const res = await api.post(
      sphereOneEndpoints.createOrRecoverAccount,
      {
        target: params.target,
        pinCode: params.pinCode,
      },
      { headers: { Authorization: `Bearer ${params.accessToken}` } }
    );
    const createOrRecoverAccountResponse =
      res.data as createOrRecoverAccountResponse;
    if (!createOrRecoverAccountResponse.data)
      throw new Error(
        createOrRecoverAccountResponse.error ?? "Unable to get Tokenized Share."
      );
    return createOrRecoverAccountResponse.data;
  },
};

export const handleError = (error: any): string => {
  if (error instanceof AxiosError) {
    const errorMessage = error.response?.data?.error ?? error.message ?? error;
    return errorMessage;
  } else {
    return error.error || error.message;
  }
};

export default api;
