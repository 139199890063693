import React from "react";

export interface SuccessPinProps {
  proceedAfterConfirm?: boolean;
  platform?: string;
  url?: string;
  getShareUrl?: string;
}

export const Success = ({
  proceedAfterConfirm,
  platform,
  url,
  getShareUrl
}: SuccessPinProps) => {

  const onClose = () => {
    if (proceedAfterConfirm) {
      // proceed to PinCode.tsx to get Tokenized Share
      window.location.href = `${window.location.origin}?${getShareUrl}}&skipCheck=true`;
    } else {
      // or else, send callback to parent opener to confirm success
      const data = {
        data: { code: "PIN", status: "OK" },
        error: null
      };
      if (platform) {
        // do something platform-specific
        window.location.href = `${url}?data=${JSON.stringify(data)}${platform === "ios" ? "#" : ""}`;
      } else {
        if (window.top !== window.self) window.top?.postMessage(data, "*"); // for embedded webviews and iframes
        else window.opener.postMessage(data, "*"); // for popup windows
        window.close();
      }
    }
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: "50",
          padding: "1rem",
          overflowX: "hidden",
          overflowY: "auto",
          maxHeight: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            background: "#1F2A37",
            borderColor: "#374151",
            borderRadius: "8px",
            boxShadow:
              "0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
            position: "relative",
            maxWidth: "390px",
            margin: "0 auto",
            padding: "var(--8, 32px)",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
            >
              <g clipPath="url(#clip0_599_9933)">
                <path
                  d="M32 0C25.671 0 19.4841 1.87677 14.2218 5.39297C8.95939 8.90917 4.85787 13.9069 2.43587 19.7541C0.0138651 25.6014 -0.619842 32.0355 0.614885 38.2429C1.84961 44.4503 4.89732 50.1521 9.3726 54.6274C13.8479 59.1027 19.5497 62.1504 25.7571 63.3851C31.9645 64.6198 38.3986 63.9861 44.2459 61.5641C50.0931 59.1421 55.0908 55.0406 58.607 49.7782C62.1232 44.5159 64 38.329 64 32C63.9907 23.5159 60.6163 15.382 54.6171 9.38287C48.618 3.38373 40.4841 0.00931711 32 0ZM43.8624 27.8624L31.0624 40.6624C30.4623 41.2623 29.6485 41.5993 28.8 41.5993C27.9515 41.5993 27.1377 41.2623 26.5376 40.6624L20.1376 34.2624C19.5547 33.6589 19.2322 32.8505 19.2395 32.0115C19.2467 31.1725 19.5833 30.3699 20.1766 29.7766C20.7699 29.1833 21.5725 28.8467 22.4115 28.8394C23.2506 28.8321 24.0589 29.1547 24.6624 29.7376L28.8 33.8752L39.3376 23.3376C39.9411 22.7547 40.7495 22.4321 41.5885 22.4394C42.4275 22.4467 43.2301 22.7833 43.8234 23.3766C44.4167 23.9699 44.7533 24.7725 44.7606 25.6115C44.7679 26.4505 44.4453 27.2589 43.8624 27.8624Z"
                  fill="#31C48D"
                />
              </g>
              <defs>
                <clipPath id="clip0_599_9933">
                  <rect width="64" height="64" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          {/* HEADER */}
          <div
            style={{
              display: "flex",
              marginBottom: "24px",
            }}
          >
            <span
              style={{
                color: "var(--white, #FFF)",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "500",
                textAlign: "start",
              }}
            >
              Congratulations!
            </span>
          </div>

          {/* BODY */}
          <div style={{ display: "flex", marginBottom: "24px" }}>
            <span
              style={{ color: "var(--gray-400, #9CA3AF)", fontSize: "14px" }}
            >
              You've successfully set up your purchase confirmation PIN
            </span>
          </div>
          <button
            onClick={onClose}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 20px",
              alignSelf: "stretch",
              borderRadius: "8px",
              backgroundColor: "#1C64F2",
              border: "none",
            }}
          >
            Continue shopping
          </button>
        </div>
      </div>
    </>
  );
};
