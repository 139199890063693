import React from "react";

export default function Input({
  error,
  index,
  code,
  setCode,
}: {
  error?: boolean;
  index: number;
  code: string[];
  setCode: (x: string[]) => void;
}) {
  const handleInputChange = (index: number, value: string) => {
    const newCode = [...code]; // Create a copy of the original array
    newCode[index] = value; // Update the corresponding element
    setCode(newCode); // Update the state with the new array
  };

  return (
    <input
      onChange={(event) => handleInputChange(index, event.target.value)}
      placeholder="X"
      maxLength={1}
      type="password"
      style={{
        display: "flex",
        padding: "var(--3, 12px) var(--4, 16px)",
        alignItems: "center",
        width: "10px",
        borderRadius: "8px",
        border: error ? '1px solid var(--red-500, #F05252)' : 0,
        marginRight: "20px",
        backgroundColor: "#374151",
        outline: "none",
      }}
    />
  );
}
