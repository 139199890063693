import React, { Dispatch, SetStateAction } from "react";
import Input from "./Input";
import { Step } from "./AddPinCode";

interface AddPinCodeProps {
  pincode: string[];
  setCode: Dispatch<SetStateAction<string[]>>;
  setStep: (step: Step) => void;
}

export const SetPinCode: React.FC<AddPinCodeProps> = ({
  pincode,
  setCode,
  setStep,
}) => {

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: "50",
          padding: "1rem",
          overflowX: "hidden",
          overflowY: "auto",
          maxHeight: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            background: "#1F2A37",
            borderColor: "#374151",
            borderRadius: "8px",
            boxShadow:
              "0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
            position: "relative",
            maxWidth: "390px",
            margin: "0 auto",
            padding: "var(--8, 32px)",
          }}
        >
          {/* HEADER */}
          <div
            style={{
              display: "flex",
              marginBottom: "24px",
            }}
          >
            <span
              style={{
                color: "var(--white, #FFF)",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "500",
                textAlign: "start",
              }}
            >
              To enhance security, please set up a PIN to authorize your
              purchases.
            </span>
          </div>

          {/* BODY */}
          <div style={{ display: "flex", marginBottom: "24px" }}>
            <span
              style={{ color: "var(--gray-400, #9CA3AF)", fontSize: "14px" }}
            >
              Please enter a four-digit PIN to confirm your purchases.
            </span>
          </div>

          {/* PIN INPUTS */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginBottom: "24px",
            }}
          >
            <Input index={0} code={pincode} setCode={setCode} />
            <Input index={1} code={pincode} setCode={setCode} />
            <Input index={2} code={pincode} setCode={setCode} />
            <Input index={3} code={pincode} setCode={setCode} />
          </div>
          <button
            onClick={() => setStep(Step.CONFIRM)}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 20px",
              alignSelf: "stretch",
              borderRadius: "8px",
              backgroundColor: "#1C64F2",
              border: "none",
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};
