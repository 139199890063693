import React, { useEffect, useState } from "react";
import Input from "./Input";
import { useLocation } from "react-router-dom";
import { SphereOneApi, handleError } from "../api";
import { getSearchParams, removeStartingQuestionMark } from "../utils/urls";

export const PinCode = () => {
  const [state, setState] = useState({
    target: "",
    accessToken: "",
  });
  const location = useLocation();
  const [code, setCode] = useState<string[]>([]);
  const [platform, setPlatform] = useState({
    type: "",
    redirectUrl: "",
  });
  const [error, setError] = useState<string>("");

  const closeButton = (
    <button
      onClick={() => window.close()}
      style={{
        position: "absolute",
        right: 24,
        top: 24.5,
        padding: 2,
        background: "transparent",
        border: "none",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M9.61317 8L15.6517 1.96146C15.7607 1.85622 15.8476 1.73033 15.9074 1.59114C15.9672 1.45195 15.9986 1.30225 16 1.15077C16.0013 0.999286 15.9724 0.849059 15.915 0.708852C15.8577 0.568645 15.773 0.441266 15.6658 0.334148C15.5587 0.22703 15.4314 0.142318 15.2911 0.0849552C15.1509 0.0275921 15.0007 -0.00127326 14.8492 4.30753e-05C14.6978 0.00135941 14.548 0.0328313 14.4089 0.0926224C14.2697 0.152413 14.1438 0.239326 14.0385 0.34829L8 6.38683L1.96146 0.34829C1.74629 0.140474 1.45811 0.0254816 1.15898 0.0280809C0.859851 0.0306803 0.57371 0.150663 0.362187 0.362187C0.150663 0.57371 0.0306803 0.859851 0.0280809 1.15898C0.0254816 1.45811 0.140474 1.74629 0.34829 1.96146L6.38683 8L0.34829 14.0385C0.239326 14.1438 0.152413 14.2697 0.0926224 14.4089C0.0328313 14.548 0.00135941 14.6978 4.30753e-05 14.8492C-0.00127326 15.0007 0.0275921 15.1509 0.0849552 15.2911C0.142318 15.4314 0.22703 15.5587 0.334148 15.6658C0.441266 15.773 0.568645 15.8577 0.708852 15.915C0.849059 15.9724 0.999286 16.0013 1.15077 16C1.30225 15.9986 1.45195 15.9672 1.59114 15.9074C1.73033 15.8476 1.85622 15.7607 1.96146 15.6517L8 9.61317L14.0385 15.6517C14.2537 15.8595 14.5419 15.9745 14.841 15.9719C15.1401 15.9693 15.4263 15.8493 15.6378 15.6378C15.8493 15.4263 15.9693 15.1401 15.9719 14.841C15.9745 14.5419 15.8595 14.2537 15.6517 14.0385L9.61317 8Z"
          fill="#9CA3AF"
        />
      </svg>
    </button>
  );

  const warningIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <g clip-path="url(#clip0_862_3147)">
        <path
          d="M8 0.5C6.41775 0.5 4.87103 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967 5.43853C0.00346627 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038 2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965 11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0822 16 8.5C15.9977 6.37898 15.1541 4.3455 13.6543 2.84572C12.1545 1.34593 10.121 0.502329 8 0.5V0.5ZM8 12.5C7.84178 12.5 7.68711 12.4531 7.55555 12.3652C7.42399 12.2773 7.32145 12.1523 7.2609 12.0061C7.20035 11.86 7.18451 11.6991 7.21537 11.5439C7.24624 11.3887 7.32244 11.2462 7.43432 11.1343C7.5462 11.0224 7.68875 10.9462 7.84393 10.9154C7.99911 10.8845 8.15997 10.9003 8.30615 10.9609C8.45233 11.0214 8.57727 11.124 8.66518 11.2555C8.75308 11.3871 8.8 11.5418 8.8 11.7C8.8 11.9122 8.71572 12.1157 8.56569 12.2657C8.41566 12.4157 8.21218 12.5 8 12.5ZM8.8 9.3C8.8 9.51217 8.71572 9.71565 8.56569 9.86568C8.41566 10.0157 8.21218 10.1 8 10.1C7.78783 10.1 7.58435 10.0157 7.43432 9.86568C7.28429 9.71565 7.2 9.51217 7.2 9.3V5.3C7.2 5.08783 7.28429 4.88434 7.43432 4.73431C7.58435 4.58428 7.78783 4.5 8 4.5C8.21218 4.5 8.41566 4.58428 8.56569 4.73431C8.71572 4.88434 8.8 5.08783 8.8 5.3V9.3Z"
          fill="#F98080"
        />
      </g>
      <defs>
        <clipPath id="clip0_862_3147">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const submit = async () => {
    try {
      if (error) setError("");

      const res = await SphereOneApi.getShare({
        accessToken: state.accessToken,
        target: state.target,
        pinCode: code.join(""),
      });
      const data = {
        data: { code: "DEK", share: res },
        error: null, // null for now, if successful
      };
      if (platform.type && platform.type !== "web") {
        // for Native Platforms
        window.location.href = `${platform.redirectUrl}?data=${JSON.stringify(
          data
        )}${platform.type === "ios" ? "#" : ""}`;
      } else {
        // for Web Platforms
        if (window.top !== window.self) {
          // for embedded webviews and iframes
          window.top?.postMessage(data, "*");
        } else {
          // for popup windows
          window.opener.postMessage(data, "*");
        }
        setTimeout(() => window.close(), 1000);
      }
    } catch (e: any) {
      console.error(e);
      setError(handleError(e));
    }
  };

  useEffect(() => {
    // Parse query parameters from location.search
    // Access specific query parameters
    const searchParams = removeStartingQuestionMark(location.search);
    const { target, accessToken, platform, redirectUrl, skipCheck } =
      getSearchParams(searchParams);

    const checkUser = async () => {
      try {
        // check for user
        const user = await SphereOneApi.getUser({ accessToken });
        if (!user.isPinCodeSetup) {
          // if user does not have a pin code, user need to set one up
          window.location.href = `${window.location.origin}/add?${searchParams}&proceedAfterConfirm=true`;
        }
      } catch (e: any) {
        setError(handleError(e));
      }
    };

    // this will only be True or NOT Undefined, if it was sent from AddPinCode.tsx
    if (!skipCheck || skipCheck === "false") checkUser();

    if (platform) {
      setPlatform({ type: platform, redirectUrl: redirectUrl });
    }
    setState({
      ...state,
      target: target as string,
      accessToken: accessToken as string,
    });
  }, [location]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: "50",
          padding: "1rem",
          overflowX: "hidden",
          overflowY: "auto",
          maxHeight: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            background: "#1F2A37",
            borderColor: "#374151",
            borderRadius: "8px",
            boxShadow:
              "0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
            position: "relative",
            maxWidth: "390px",
            margin: "0 auto",
            padding: "var(--8, 32px)",
          }}
        >
          {closeButton}

          {/* HEADER */}
          <div
            style={{
              display: "flex",
              marginBottom: "24px",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "var(--white, #FFF)",
                fontSize: "21px",
                fontStyle: "normal",
                fontWeight: "500",
                textAlign: "start",
              }}
            >
              Enter your PIN to confirm the purchase
            </span>
          </div>

          {/* BODY */}
          <div style={{ display: "flex", marginBottom: "24px" }}>
            <span
              style={{ color: "var(--gray-400, #9CA3AF)", fontSize: "14px" }}
            >
              You're about to make a purchase. To proceed, please enter your
              purchase PIN.
            </span>
          </div>

          {/* PIN INPUTS */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              marginBottom: "24px",
            }}
          >
            <Input
              index={0}
              code={code}
              setCode={setCode}
              error={error ? true : false}
            />
            <Input
              index={1}
              code={code}
              setCode={setCode}
              error={error ? true : false}
            />
            <Input
              index={2}
              code={code}
              setCode={setCode}
              error={error ? true : false}
            />
            <Input
              index={3}
              code={code}
              setCode={setCode}
              error={error ? true : false}
            />
          </div>
          {error && (
            <div
              style={{
                display: "flex",
                padding: "var(--4, 16px)",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "var(--3, 12px)",
                alignSelf: "stretch",
                borderRadius: "6px",
                border: "1px solid var(--red-900, #771D1D)",
                background: "var(--gray-800, #1F2A37)",
                marginBottom: "24px",
              }}
            >
              <div>
                {warningIcon}
                <span
                  style={{
                    color: "#F98080",
                    fontSize: "16px",
                    fontWeight: 600,
                    marginLeft: "8px",
                  }}
                >
                  Oops!
                </span>
              </div>
              <span
                style={{ fontSize: "14px", fontWeight: 400, color: "#F98080" }}
              >
                {error}
              </span>{" "}
            </div>
          )}
          <button
            onClick={submit}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 20px",
              alignSelf: "stretch",
              borderRadius: "8px",
              backgroundColor: "#1C64F2",
              border: "none",
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};
