import React, { useEffect, useState } from "react";
import Input from "./Input";
import { Step } from "./AddPinCode";
import { SphereOneApi, handleError } from "../api";

interface ConfirmPinProps {
  accessToken: string;
  pincode: string;
  setStep: (step: Step) => void;
}

export const ConfirmPin: React.FC<ConfirmPinProps> = ({
  accessToken,
  pincode,
  setStep,
}) => {
  const [confirmCode, setConfirmCode] = useState<string[]>([]);
  const [error, setError] = useState<string>("");

  const submit = async () => {
    if (confirmCode.join("") !== pincode)
      setError("Pin code doesn't match. Please try again.");
    else {
      try {
        const res = await SphereOneApi.setPin({ accessToken, pincode });
        if (res === "Pin code added and wallets successfully updated")
          setStep(Step.SUCCESS);
        else throw new Error("Something went wrong.");
      } catch (e: any) {
        console.error(e);
        setError(handleError(e));
      };
    }
  };

  const warningIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <g clip-path="url(#clip0_862_3147)">
        <path
          d="M8 0.5C6.41775 0.5 4.87103 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967 5.43853C0.00346627 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038 2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965 11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0822 16 8.5C15.9977 6.37898 15.1541 4.3455 13.6543 2.84572C12.1545 1.34593 10.121 0.502329 8 0.5V0.5ZM8 12.5C7.84178 12.5 7.68711 12.4531 7.55555 12.3652C7.42399 12.2773 7.32145 12.1523 7.2609 12.0061C7.20035 11.86 7.18451 11.6991 7.21537 11.5439C7.24624 11.3887 7.32244 11.2462 7.43432 11.1343C7.5462 11.0224 7.68875 10.9462 7.84393 10.9154C7.99911 10.8845 8.15997 10.9003 8.30615 10.9609C8.45233 11.0214 8.57727 11.124 8.66518 11.2555C8.75308 11.3871 8.8 11.5418 8.8 11.7C8.8 11.9122 8.71572 12.1157 8.56569 12.2657C8.41566 12.4157 8.21218 12.5 8 12.5ZM8.8 9.3C8.8 9.51217 8.71572 9.71565 8.56569 9.86568C8.41566 10.0157 8.21218 10.1 8 10.1C7.78783 10.1 7.58435 10.0157 7.43432 9.86568C7.28429 9.71565 7.2 9.51217 7.2 9.3V5.3C7.2 5.08783 7.28429 4.88434 7.43432 4.73431C7.58435 4.58428 7.78783 4.5 8 4.5C8.21218 4.5 8.41566 4.58428 8.56569 4.73431C8.71572 4.88434 8.8 5.08783 8.8 5.3V9.3Z"
          fill="#F98080"
        />
      </g>
      <defs>
        <clipPath id="clip0_862_3147">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  const chevronLeft = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <g clip-path="url(#clip0_599_9459)">
        <path
          d="M11.0958 16.5C10.6841 16.4999 10.2893 16.3315 9.99824 16.0318L3.78852 9.63963C3.49748 9.33995 3.33398 8.93356 3.33398 8.50982C3.33398 8.08608 3.49748 7.67968 3.78852 7.38001L9.99824 0.987862C10.1414 0.835233 10.3128 0.713491 10.5022 0.62974C10.6916 0.545988 10.8953 0.501904 11.1014 0.50006C11.3075 0.498216 11.512 0.538649 11.7027 0.619C11.8935 0.699351 12.0669 0.81801 12.2126 0.968054C12.3584 1.1181 12.4737 1.29652 12.5517 1.49291C12.6298 1.68931 12.6691 1.89974 12.6673 2.11192C12.6655 2.32411 12.6226 2.5338 12.5413 2.72877C12.4599 2.92374 12.3417 3.10007 12.1934 3.24748L7.08122 8.50982L12.1934 13.7722C12.4104 13.9956 12.5582 14.2804 12.6181 14.5903C12.678 14.9002 12.6472 15.2215 12.5298 15.5135C12.4123 15.8054 12.2134 16.055 11.9581 16.2306C11.7029 16.4062 11.4028 16.4999 11.0958 16.5Z"
          fill="#F9FAFB"
        />
      </g>
      <defs>
        <clipPath id="clip0_599_9459">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );

  useEffect(() => {
    setError("");
  }, [confirmCode]);

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: "50",
          padding: "1rem",
          overflowX: "hidden",
          overflowY: "auto",
          maxHeight: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            background: "#1F2A37",
            borderColor: "#374151",
            borderRadius: "8px",
            boxShadow:
              "0px 1px 2px -1px rgba(0, 0, 0, 0.10), 0px 1px 3px 0px rgba(0, 0, 0, 0.10)",
            position: "relative",
            maxWidth: "390px",
            margin: "0 auto",
            padding: "32px",
          }}
        >
          {/* HEADER */}
          <div
            style={{
              display: "flex",
              marginBottom: "24px",
              alignItems: "center",
            }}
          >
            <div onClick={() => setStep(Step.ADD)}>{chevronLeft}</div>
            <span
              style={{
                color: "var(--white, #FFF)",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "500",
                textAlign: "start",
                marginLeft: "24px",
              }}
            >
              Confirm PIN
            </span>
          </div>

          {/* BODY */}
          <div style={{ display: "flex", marginBottom: "24px" }}>
            <span
              style={{ color: "var(--gray-400, #9CA3AF)", fontSize: "14px" }}
            >
              Please confirm your PIN by entering it again.
            </span>
          </div>

          {/* PIN INPUTS */}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              marginBottom: "24px",
            }}
          >
            <Input
              error={error ? true : false}
              index={0}
              code={confirmCode}
              setCode={setConfirmCode}
            />
            <Input
              error={error ? true : false}
              index={1}
              code={confirmCode}
              setCode={setConfirmCode}
            />
            <Input
              error={error ? true : false}
              index={2}
              code={confirmCode}
              setCode={setConfirmCode}
            />
            <Input
              error={error ? true : false}
              index={3}
              code={confirmCode}
              setCode={setConfirmCode}
            />
          </div>

          {error && (
            <div
              style={{
                display: "flex",
                padding: "var(--4, 16px)",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "var(--3, 12px)",
                alignSelf: "stretch",
                borderRadius: "6px",
                border: "1px solid var(--red-900, #771D1D)",
                background: "var(--gray-800, #1F2A37)",
                marginBottom: "24px",
              }}
            >
              <div>
                {warningIcon}
                <span
                  style={{
                    color: "#F98080",
                    fontSize: "16px",
                    fontWeight: 600,
                    marginLeft: "8px",
                  }}
                >
                  Oops!
                </span>
              </div>
              <span
                style={{ fontSize: "14px", fontWeight: 400, color: "#F98080" }}
              >
                {error}
              </span>{" "}
            </div>
          )}

          <button
            onClick={submit}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px 20px",
              alignSelf: "stretch",
              borderRadius: "8px",
              backgroundColor: "#1C64F2",
              border: "none",
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};
