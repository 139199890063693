import React from "react";
import { PinCode } from "./components/PinCode";
import { Route, Routes } from "react-router-dom";
import AddPinCode from "./components/AddPinCode";

function App() {
  return (
    <Routes>
      <Route path="/" element={<PinCode />} />
      <Route path="/add" element={<AddPinCode />} />
    </Routes>
  );
}

export default App;
