export interface UrlParams {
  [k: string]: string;
};

export function getSearchParams(url: string): UrlParams {
  const searchParams = new URLSearchParams(url);
  return Object.fromEntries(searchParams);
};

export function removeTrailingSlash(word: string): string {
  return word.replace(/\/+$/, "");
}

export function removeStartingQuestionMark(word: string): string {
  return word.replace(/^\?+/, "");
}
